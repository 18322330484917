import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Spain",
  "subtitle": "May 2015",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.renfe.com/EN/viajeros/index.html"
        }}>{`Renfe train tickets`}</a>{` discounts are first come first serve last I booked`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jam%C3%B3n_ib%C3%A9rico"
        }}>{`Iberian Jamon`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Fideu%C3%A0"
        }}>{`Fideua`}</a>{` - Similar to Paella`}</li>
    </ul>
    <h2 {...{
      "id": "barcelona"
    }}>{`Barcelona`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tmb.cat/en/home"
        }}>{`TMB`}</a>{` - Metro`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9XeA91paPisFCsjR6"
        }}>{`Baluard bakery`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hzw1kiunf8KbagYWA"
        }}>{`Mercado de La Boqueria`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Xxv72Jtf7ej9EtWx6"
        }}>{`La Flauta`}</a>{` - The food here was great, especially the Huevos Cabreados`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Q8Ji9JfiJ3L8vgaYA"
        }}>{`Ciudad Condal`}</a>{` - Tapas with multiple locations`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hN83Mif7T8SuTTqAA"
        }}>{`Restaurant Ca La Montse`}</a>{` - Had Fideua here based on a recommendation`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wZa76rNyNog9SBZB8"
        }}>{`Manna Gelats`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3b8yog5hJSQMKRQRA"
        }}>{`Parc de la Ciutadella`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mf2NuoKKbU3ZSzVV6"
        }}>{`Montjuïc Magic Fountain`}</a>{` - Nighttime water fountain show`}</li>
    </ul>
    <h4 {...{
      "id": "works-of-antoni-gaudí"
    }}>{`Works of Antoni Gaudí`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8exP6LQAK32rFMyz5"
        }}>{`La Sagrada Familia`}</a>{` (`}<a parentName="li" {...{
          "href": "https://whc.unesco.org/en/list/320/"
        }}>{`UNESCO`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`I've seen so many churches and cathedrals but this is an absolute must visit!`}</li>
          <li parentName="ul">{`At the time, I only got to see the Nativity Facade since there was construction`}</li>
          <li parentName="ul">{`Buy tickets online to the ridiculous queue especially on weekends or holidays!`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/neE1sLm3iBXggF1EA"
        }}>{`Casa Milà`}</a>{` - Buy tickets online`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mjKWFhRF3NJ75fWW6"
        }}>{`Casa Batlló`}</a>{` - Buy tickets online`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MqRSVmXQ9iVxaVTp8"
        }}>{`Park Güell`}</a>{` - Buy tickets online`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Jbr2JZJTj6z3VNrz7"
            }}>{`Casa Museu de Gaudí`}</a>{` - Small and a bit crowded`}</li>
          <li parentName="ul">{`Very unique park but there's a fair amount of walking`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "works-of-lluís-domènech-i-montaner"
    }}>{`Works of Lluís Domènech i Montaner`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/5vxod3JhAhge6JDt7"
        }}>{`Palau de la Música Catalana`}</a>{` (`}<a parentName="li" {...{
          "href": "https://whc.unesco.org/en/list/804/"
        }}>{`UNESCO`}</a>{`) - The interior is amazing; I recommending getting tickets to a classical concert!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JjbP9XCi1bvP9j5R7"
        }}>{`Hospital de Sant Pau`}</a>{` - Walking distance from La Sagrada Familia`}</li>
    </ul>
    <h4 {...{
      "id": "montserrat"
    }}>{`Montserrat`}</h4>
    <ul>
      <li parentName="ul">{`I only did a daytrip from Barcelona`}</li>
      <li parentName="ul">{`Limited food options so consider bringing lunch?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/enXGKMtCc6oWU3DM9"
        }}>{`Abadia de Montserrat`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Virgin_of_Montserrat"
            }}>{`Virgin of Montserrat`}</a>{` line moves pretty fast but was long`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NpKiRhE6dRRqr3LF8"
        }}>{`Museum of Montserrat`}</a></li>
      <li parentName="ul">{`Scenic viewpoints and trails are well marked`}</li>
    </ul>
    <h2 {...{
      "id": "san-sebastian"
    }}>{`San Sebastian`}</h2>
    <p>{`One of my top food places!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Basque_Country_(autonomous_community)"
        }}>{`Basque Country`}</a>{` is the place to be to eat!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pincho"
        }}>{`Pintxos`}</a>{` are similar to tapas but usually held together with a skewer of sorts`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vHxsJb8zSQyHdBhGA"
        }}>{`Akelarre`}</a>{` - Amazing views and good food`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hERVRMqND9TWJjHi9"
        }}>{`Bar Nestor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Basque_Culinary_Center"
        }}>{`Basque Culinary Center`}</a></li>
    </ul>
    <h4 {...{
      "id": "pintxo-bars"
    }}>{`Pintxo bars`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JoDGZfKCV3h9a89V7"
        }}>{`Astelena Bar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Lueuip8Q2kJtZSkX7"
        }}>{`Bergara`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Vieira"
            }}>{`Vieira`}</a>{` - scallop`}</li>
          <li parentName="ul">{`Itxaso - Monkfish and seafood puree`}</li>
          <li parentName="ul">{`Txalupa - Prawns with mushroom gratin`}</li>
          <li parentName="ul">{`Udaberri - Courgette tart and crayfish puree`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SkPYYfJtrUYRdSEy7"
        }}>{`La Mejillonera`}</a></li>
    </ul>
    <h4 {...{
      "id": "ice-cream"
    }}>{`Ice cream`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zTaHck9FdNCAgV6n9"
        }}>{`Gelateria Boulevard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mGKRuS521UccBzW29"
        }}>{`Papperino Ilgelato`}</a>{` - Cookies Papperino flavor`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kF4w7bYRFn3nN8Fi6"
        }}>{`Pastelería Oiartzun`}</a>{` - Kinder flavor`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WGgeg2Y65gFuKnhi6"
        }}>{`Monte Urgull`}</a>{` - Hike to Jesus for the view!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/GudGfvuCdeNiRHgq5"
        }}>{`Plaza de la Constitucion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9pYaamdPBfQnQLMg7"
        }}>{`San Telmo Museum`}</a>{` - Free on Tuesdays`}</li>
      <li parentName="ul">{`Surfing school - The place I went to closed but there are many others!`}</li>
    </ul>
    <h2 {...{
      "id": "madrid"
    }}>{`Madrid`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/apbMsbAgcyfpmRcQA"
        }}>{`Sobrino de Botín`}</a>{` - The world’s oldest restaurant and mentioned by many famous authors such as Ernest Hemingway`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      